import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import { toWords } from 'number-to-words';
import { CourrierContractDetails, EvESolarSystem } from '../app/AppTypes';
import { MaxContractCargoSize, MaxContractCollateral, calculatePrice } from '../app/utils/EvECourrierCalculator';
import { Store } from '../app/store/store';
import Select from 'react-select';

const SelectOptions = Store.getSystems().map(system => {
    return makeSelectOptionFromSystem(system);
})

function toCuteNumber(x: number | string): string {
    if(Number.isNaN(x)){return "0"}
    return (x + "").replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function getParam(paramName: string): string {
    const queryParameters = new URLSearchParams(window.location.search)
    return queryParameters.get(paramName) == null ? "" : queryParameters.get(paramName)!;
}
function makeSelectOptionFromSystem(system: EvESolarSystem): { value: EvESolarSystem, label: string } {
    return { value: system, label: system.SolarSystem + " - " + system.Region }
}


export default function CalculatorModule() {


    const urlFrom = getParam("from");
    const urlTo = getParam("to");
    let urlSize = parseInt(getParam("size"));
    if(Number.isNaN(urlSize)){
        urlSize = 0;
    }
    let urlCollat = parseInt(getParam("col"));
    if(Number.isNaN(urlCollat)){
        urlCollat = 0;
    }

    const [cargo, setCargo] = useState(urlSize);
    const [collateral, setCollateral] = useState(urlCollat);

    let defaultPickupSystem = Store.findSystemByName(urlFrom);
    if (defaultPickupSystem == null) {
        defaultPickupSystem = SelectOptions[0].value;
    }
    const [pickupSystem, setPickupSystem] = useState(defaultPickupSystem);

    let defaultDropSystem = Store.findSystemByName(urlTo);
    if (defaultDropSystem == null) {
        defaultDropSystem = SelectOptions[0].value;
    }
    const [dropSystem, setDropSystem] = useState(defaultDropSystem);




    const handleChangeCargo = (event: { target: { value: string; }; }) => {
        let strNumber = toCuteNumber(event.target.value);
        let number = Number(strNumber.replace(/ /g, ""));
        if (number < 0) {
            number = 0;
            strNumber = "";
        }
        if (number > MaxContractCargoSize) {
            number = MaxContractCargoSize;
        }
        setCargo(number);
    };


    const handleChangeCollateral = (event: { target: { value: string; }; }) => {
        let strNumber = toCuteNumber(event.target.value);
        let number = Number(strNumber.replace(/ /g, ""));
        if (number < 0) {
            number = 0;
            strNumber = "";
        }
        if (number > MaxContractCollateral) {
            number = MaxContractCollateral;
        }
        if(Number.isNaN(number)){
            number = 0;
        }
        setCollateral(number);
    };


    const renderCollateralTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {toWords(collateral)}
        </Tooltip>
    );

    const contractDetails: CourrierContractDetails = calculatePrice(
        pickupSystem,
        dropSystem,
        cargo,
        collateral);

    let newUrl = "";

    if (pickupSystem) {
        newUrl += "&from=" + pickupSystem.SolarSystem;
    }
    if (dropSystem) {
        newUrl += "&to=" + dropSystem.SolarSystem;
    }
    if (cargo> 0) {
        newUrl += "&size=" + cargo;
    }
    if (collateral > 0) {
        newUrl += "&col=" + collateral;
    }

    window.history.pushState({}, "", "/?" + newUrl.substring(1, newUrl.length));

    return (
        <div id="calculator" className='calculator'>
            <Form>
                <Form.Label>Pick up system</Form.Label>
                <Select aria-label='Pick up' value={makeSelectOptionFromSystem(pickupSystem!)} options={SelectOptions} onChange={(v) => { setPickupSystem(v!.value) }} />
                <Form.Label>Drop off system</Form.Label>
                <Select aria-label='Drop in' value={makeSelectOptionFromSystem(dropSystem)} options={SelectOptions} onChange={(v) => { setDropSystem(v!.value) }} />
                <Form.Label>Cargo Size (m3) [350 000 max]</Form.Label>
                <Form.Control value={toCuteNumber(cargo)} onChange={handleChangeCargo} />
                <Form.Label>Collateral (isk) [10 Billion max]</Form.Label>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderCollateralTooltip}
                >
                    <Form.Control value={toCuteNumber(collateral)} onChange={handleChangeCollateral} />
                </OverlayTrigger>
                <br />
                <Card>
                    <Card.Body>
                        <Card.Text>
                            Cost of size: {toCuteNumber(contractDetails.cargoPrice)}<br />
                            Cost of Collateral (0.40%): {toCuteNumber(contractDetails.collateralPrice)}<br />
                            Total: {toCuteNumber(contractDetails.total)}<br />
                            Price per m3: {toCuteNumber((contractDetails.total/cargo).toFixed(0))}
                            <br />
                            {contractDetails.messages?.map((message, i) =>
                                <>
                                <hr />
                                    <span key={"message" + i} className='warning'>{message}</span>
                                </>
                            )}
                        </Card.Text>

                    </Card.Body>
                </Card>
                <br /><br />
                <Card>
                    <Card.Body>
                        <Card.Title>Contract information</Card.Title>
                        <Card.Text>
                            Contract type: <b>Courier</b><br />
                            Reward: <b>{toCuteNumber(contractDetails.total)}</b><br />
                            Collateral: <b>{toCuteNumber(collateral)}</b><br />
                            Contract to: <b>Suspicious Intentions</b>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Form>
        </div >
    );
}