import React from "react";

import HeaderModule from '../section/Header.sec';
import FooterModule from "../section/Footer.sec";
import MainModule from "../section/Main.sec";

export default function MainPage(){
    return (
        <>
            <HeaderModule />
            <MainModule />
            <FooterModule />
        </>
    );
}