import React from "react";
import { Col, Row } from "react-bootstrap";

export default function FooterSection() {
    return (
        <footer className="footer">
            <div id="contact">
                <br />
                <h3>Contact us in game or via Discord</h3>
                <p>If you have an exceptional request don't be afraid to reach out to us, we will try our best to help you!</p>
                <Row>
                    <Col>
                      <a href="https://evewho.com/corporation/98659319">Corporation</a>
                    </Col>
                    <Col>
                      <a href="https://discord.gg/33KHmq8mFp">Discord</a>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}
