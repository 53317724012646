
import { EvESolarSystem } from "../AppTypes";
import systems from "./eveSystems.json"

export class Store{
    public static getSystems():EvESolarSystem[]{
        return systems;
    }
    public static findSystemByName(systemName:string){
        return systems.find((system:EvESolarSystem)=>{
            return system.SolarSystem === systemName;
        });
    }
}