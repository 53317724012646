import React from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HowToModule from "../modules/HowTo.mod";
import CalculatorModule from "../modules/Calculator.mod";

export default function MainSection() {
    return (
        <>
            <Row>
                <Col><img src="assets/welcome.png" className="img" alt="welcome"></img> </Col>
            </Row>
            <Row>

                <Col>
                    <div id="info" className="center-text">
                        <p>Just make the contract</p>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col sm={12} md={6}><HowToModule></HowToModule></Col>
                <Col sm={12} md={6}><CalculatorModule></CalculatorModule></Col>
            </Row>
        </>
    );
}