import { EvESolarSystem, CourrierContractDetails } from "../AppTypes";
import { isNPC } from "./EveSystemUtils";

export const MaxContractCollateral = 10*1000*1000*1000; //10B isk
export const MaxContractCargoSize = 350000; //350k m3

export const PriceContractPerM3 = 250; // 250isk per m3
export const TaxContractCollateral = 0.004; // 0.40%
export const IgnoreCollateralPriceBelow = 1*1000*1000 //1M

export const MinimunContractPriceNPC = 20*1000*1000; //20M
export const MinimunContractPriceSOV = 10*1000*1000; //10M

export function calculatePrice(
    pickupSystem:EvESolarSystem, 
    dropSystem:EvESolarSystem, 
    cargoSize:number, 
    collateralValue:number):CourrierContractDetails{
        
        let messages:string[] = [];
        

        const isNPCPrice = isNPC(pickupSystem) || isNPC(dropSystem);

        const cargoPrice = Math.floor(cargoSize * PriceContractPerM3);
        let collateralPrice = Math.floor(collateralValue * TaxContractCollateral);
        if(collateralPrice<IgnoreCollateralPriceBelow){
            collateralPrice = 0;
        }
        let total = cargoPrice + collateralPrice;

        if(isNPCPrice && total < MinimunContractPriceNPC){
            total = MinimunContractPriceNPC
            messages.push("Contract price is bellow the minimun on NPC systems (20M isk)")
        }
        if(!isNPCPrice && total < MinimunContractPriceSOV){
            total = MinimunContractPriceSOV
            messages.push("Contract price is bellow the minimun on SOV systems (10M isk)")
        }
        if(collateralValue<IgnoreCollateralPriceBelow){
            messages.push("Please set a reasonable collateral value, you are contracting a corporation and anyone on it can take your contract, please don't make it easy for the bad guys!")
        }

        return {
            pickup: pickupSystem,
            drop: dropSystem,
            total,
            cargoPrice,
            collateralPrice,
            messages: messages
        };
}