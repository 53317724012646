import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function HowToModule() {
    return (
        <center>
            <Carousel className='center' autoPlay={true} infiniteLoop={true} interval={5000}>
                <div>
                    <img src="assets/Contract-Step1.png" alt='Create courrier contract' />
                    <p className="legend">Create courrier contract</p>
                </div>
                <div>
                    <img src="assets/Contract-Step2.png" alt='Verify items and volume' />
                    <p className="legend">Verify items and volume</p>
                </div>
                <div>
                    <img src="assets/Contract-Step3.png" alt='Set destination, reward and collateral' />
                    <p className="legend">Set destination, reward and collateral</p>
                </div>
                <div>
                    <img src="assets/Contract-Step4.png" alt='Review' />
                    <p className="legend">Review</p>
                </div>
            </Carousel>
        </center>
    );
}